import * as React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import './App.scss';
import Create from './Create';
import DisplaySecret from './DisplaySecret';
import Download from './Download';

class App extends React.Component {
  public render() {
    return (
      <Router>
        <Navbar color="white" dark={false} expand="md">
			<NavbarBrand href="https://theitteam.co.nz/"><img src="/logo.png" alt="Logo" className="logo-img" /></NavbarBrand>
        </Navbar>
        <Container className="margin">
          <Routes />
        </Container>
        <Attribution />
      </Router>
    );
  }
}

const Routes = () => {
  return (
    <div>
      <Route path="/" exact={true} component={Create} />
      <Route exact={true} path="/s/:key/:password" component={DisplaySecret} />
      <Route exact={true} path="/s/:key" component={DisplaySecret} />
      <Route exact={true} path="/f/:key/:password" component={Download} />
      <Route exact={true} path="/f/:key" component={Download} />
    </div>
  );
};

const Attribution = () => {
  return (
    <Container className="text-center">
      <div className="text-muted small footer">
		<p> 
		</p>
        Managed by <a href="https://theitteam.co.nz/">The IT Team Ltd</a>
      </div>
    </Container>
  );
};
export default App;
