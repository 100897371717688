import * as React from 'react';

const Error = (
  props: { readonly display: boolean } & React.HTMLAttributes<HTMLElement>,
) =>
  props.display ? (
    <div>
      <h2>Password no longer exists</h2>
      <p className="lead">
        Please contact The IT Team to have the password re-sent. You can either reply to the ticket email you received OR call us on 0800 488 326 (Option 1).
      </p>
      
    </div>
  ) : null;

export default Error;
