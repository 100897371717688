import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clipboard from 'clipboard';
import * as React from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core'; // Import IconProp directly from fontawesome-svg-core

type LocalIconProp = IconProp; // Define a local type alias for IconProp

const Result = (
  props: {
    readonly uuid: string;
    readonly password: string;
    readonly prefix: string;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const { uuid, password, prefix } = props;
  const base = `${window.location.protocol}//${window.location.host}/#/${prefix}`;
  const short = `${base}/${uuid}`;
  const full = `${short}/${password}`;

  return (
    <div>
      <h3>Your link has been generated!</h3>
      <p>
        Make sure to copy the link below and email this back to your technician.
      </p>
      <CopyField name="full" label="Click blue button to copy:" value={full} />
      
      <p>
        <a href="https://secure.theitteam.co.nz/">Generate another link</a>
      </p>
    </div>
  );
};

const CopyField = (
  props: {
    readonly label: string;
    readonly name: string;
    readonly value: string;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  new Clipboard(`#${props.name}-b`, {
    target: () => document.getElementById(`${props.name}-i`) as Element,
  });

  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <div className="input-group mb-3">
        <div className="input-group-append">
          <Button color="primary" id={`${props.name}-b`}>
            {' '}
            <FontAwesomeIcon icon={faCopy as LocalIconProp} /> {/* Cast as LocalIconProp */}
          </Button>
        </div>
        <Input readOnly={true} id={`${props.name}-i`} value={props.value} />
      </div>
    </FormGroup>
  );
};

export default Result;
