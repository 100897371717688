import * as React from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import Result from './Result';
import { encryptMessage, postSecret, randomString } from './utils';

const Create = () => {
  const [expiration, setExpiration] = useState(1209600);
  const [error, setError] = useState<string | undefined>();
  const [secret, setSecret] = useState<string | undefined>();
  const [onetime, setOnetime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uuid, setUUID] = useState<string | undefined>();
  const [password, setPassword] = useState('');

  const submit = async () => {
    if (!secret) {
      return;
    }
    setLoading(true);
    setError(undefined); // Clear error state initially
    try {
      const pw = randomString();
      const { data, status } = await postSecret({
        expiration,
        message: await encryptMessage(secret, pw),
        one_time: onetime,
      });
      if (status !== 200) {
        setError(data.message); // Set error message if request fails
      } else {
        setUUID(data.message);
        setPassword(pw);
      }
    } catch (e) {
      setError(e.message); // Set error message if an exception occurs
    }
    setLoading(false);
  };

  return (
    <div className="text-center">
      <h1>Encrypt Password</h1>
      <Error message={error} onClick={() => setError(undefined)} />
      {uuid ? (
        <Result uuid={uuid} password={password} prefix="s" />
      ) : (
        <Form>
          <FormGroup>
            <Label>Please enter a password to generate a secure link for:</Label>
            <Input
              type="textarea"
              name="secret"
              rows="4"
              autoFocus={true}
              placeholder="Password to encrypt"
              onChange={(e) => setSecret(e.target.value)}
              value={secret || ''}
            />
          </FormGroup>
          <Lifetime expiration={expiration} setExpiration={setExpiration} />
          <OneTime setOnetime={setOnetime} onetime={onetime} />
          <Button
            disabled={loading}
            color="primary"
            size="lg"
            block={true}
            onClick={() => submit()}
          >
            {loading ? (
              <span>Encrypting password...</span>
            ) : (
              <span>Encrypt Password</span>
            )}
          </Button>
        </Form>
      )}
    </div>
  );
};

export const OneTime = (
  props: {
    readonly onetime: boolean;
    readonly setOnetime: React.Dispatch<React.SetStateAction<boolean>>;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const { onetime, setOnetime } = props;
  return (
    <FormGroup>
      <Input
        type="checkbox"
        autoComplete="off"
        onChange={() => setOnetime(!onetime)}
      />
      Expire after opening
    </FormGroup>
  );
};

export const Lifetime = (
  props: {
    readonly expiration: number;
    readonly setExpiration: React.Dispatch<React.SetStateAction<number>>;
  } & React.HTMLAttributes<HTMLElement>,
) => {
  const { expiration, setExpiration } = props;
  const buttons = [
    {
      duration: 86400,
      name: '1d',
      text: 'One Day',
    },
    {
      duration: 1209600,
      name: '2w',
      text: 'Two Weeks',
    },
    {
      duration: 2419200,
      name: '1m',
      text: 'One Month',
    },
  ];

  return (
    <FormGroup tag="fieldset">
      <FormText color="muted">
        The encrypted password will be deleted automatically after:
      </FormText>
      {buttons.map((button) => (
        <FormGroup key={button.name} check inline>
          <Label check>
            <Input
              type="radio"
              name={button.name}
              value={button.duration}
              onChange={(e) => setExpiration(+e.target.value)}
              checked={expiration === button.duration}
            />
            {button.text}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  );
};

export const Error = (
  props: { readonly message: string | undefined } & React.HTMLAttributes<HTMLElement>,
) =>
  props.message ? (
    <Alert color="danger" {...props}>
      {props.message}
    </Alert>
  ) : null;

export default Create;
